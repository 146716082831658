<template>
  <div class="wrapper">
    <!-- Navbar -->
    <header-layout></header-layout>
    <!-- /.navbar -->
    <!-- Main Sidebar Container -->
    <sidebar-layout></sidebar-layout>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <ol class="breadcrumb">
                <li v-for="(item, index) in $route.meta.breadcrumb" class="breadcrumb-item">
                  <a v-if="item.href" :href="item.href">{{ $t(item.label) }}</a>
                  {{ item.href ? "" : $t(item.label) }}
                </li>
                <li class="m-l-15" style="margin-top: -8px" v-if="$route.name == ROUTES.ADMIN.SALE_LIST_DETAIL_APARTMENT">
                  <button class="btn btn-default" type="button" @click ="backToSaleListPage()">戻る</button>
                </li>
                <li class="m-l-15" style="margin-top: -8px" v-if="$route.name == ROUTES.ADMIN.SALE_LIST_DETAIL_CUSTOMER">
                  <button class="btn btn-default" type="button" @click ="backToSaleDetailApartmentPage()">戻る</button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="container-fluid">
        <router-view :key="$route.path"></router-view>
        </div>
      </section>
    </div>

<!--    <footer class="main-footer">-->
<!--      <strong>Copyright &copy;<a href="https://thefirstone.jp/en/home-en/">The First One</a>.</strong> All rights reserved.-->
<!--    </footer>-->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>


</template>

<script>
import HeaderLayout from "@layouts/admin/HeaderLayout.vue";
import SidebarLayout from "@layouts/admin/SidebarLayout.vue";
export default {
  name: "MasterLayout",
  components: {
    "header-layout": HeaderLayout,
    "sidebar-layout": SidebarLayout,
  },

  methods: {
    backToSaleListPage() {
      if (localStorage.getItem('isBackToSaleList') == 'false') {
        this.$router.push({
          name:this.ROUTES.ADMIN.SALE_LIST_DETAIL , query: JSON.parse(localStorage.getItem('paramsBackToSaleList'))
        })
      } else {
        this.$router.push({
          name:this.ROUTES.ADMIN.SALE_LIST , query: JSON.parse(localStorage.getItem('paramsBackToSaleList'))
        })
      }

    },
    backToSaleDetailApartmentPage() {
      this.$router.push({
        name:this.ROUTES.ADMIN.SALE_LIST_DETAIL_APARTMENT ,
        query: JSON.parse(localStorage.getItem('paramsFillterCurrent'))
      })
    },
  },

  computed: {
    currentBreadCrumb() {
      console.log(this.$route.meta.breadcrumb, 'this.$route.meta.breadcrumb');
      return this.$route.meta.breadcrumb;
    }
  }
};
</script>
